import React from "react"

import EmptyLayout from "@components/empty-layout"
import SEO from "@components/seo"

const VIPiPhonePage = () => (
  <EmptyLayout bodyClassName='coachSignup'>
    <SEO 
      title="Sign up for Harper Coach"
      description="Harper Coach is 1:1 remote training with a real dog trainer, powered by an awesome app, dedicated to helping you and your pup reach your goals." 
    />
  
    <section className="stripe" id="coachForm">
      <div className="container">
        <a href="/coach/" className="backArrowButton">Go back</a>
        <h2 className="processSubhead">Step 1 of 3</h2>
        <h1 className="sectionTitle">Sign up for Coach</h1>
        <form id="form11" name="form11" className="wufoo topLabel page" acceptCharset="UTF-8" autoComplete="off" encType="multipart/form-data" method="post" noValidate
        action="https://nscramer.wufoo.com/forms/zrb7chd1ca1bm9/#public">
          <label className="desc" id="title3" htmlFor="Field3">Your name</label>
          <input id="Field3" name="Field3" type="text" className="field text medium input" maxLength="255" tabIndex="1" onKeyUp="" placeholder="Susan" />

          <label className="desc" id="title5" htmlFor="Field5">Your dog&rsquo;s name</label>
          <input id="Field5" name="Field5" type="text" className="field text medium input" maxLength="255" tabIndex="2" onKeyUp="" placeholder="Fitzgerald" />

          <label className="desc" id="title13" htmlFor="Field13">Your dog&rsquo;s breed</label>
          <input id="Field13" name="Field13" type="text" className="field text medium input" maxLength="255" tabIndex="3" onKeyUp="" placeholder="Golden Retriever" />

          <label className="desc" id="title14" htmlFor="Field14">Your dog&rsquo;s birthday</label>
          <input id="Field14" name="Field14" type="text" className="field text medium input" maxLength="255" tabIndex="4" onKeyUp="" placeholder="MM/DD/YYYY" />

          <label className="desc" id="title10" htmlFor="Field10">Your email address</label>
          <input id="Field10" name="Field10" type="email" spellCheck="false" className="field text medium input" maxLength="255" tabIndex="5" placeholder="susan@email.com" />
        
          <label className="desc notranslate" id="title11" htmlFor="Field11">Select a program</label>
          <select id="Field11" name="Field11" className="field select input medium" tabIndex="6" data-wufoo-field="dropdown" >
            <option value="New Puppy Basics" selected="selected">New Puppy Basics</option>
            <option value="New Puppy Basics">Adult Dog Basics</option>
          </select>
        
          <label className="desc" id="title16" htmlFor="Field16">Your training goals</label>
          <textarea id="Field16" name="Field16" className="field textarea medium input" spellCheck="true" rows="10" cols="50" tabIndex="7" onKeyUp="" placeholder="What's important for you and your pup to achieve?"></textarea>

          <div>
            <input id="saveForm" name="saveForm" className="btn btn--primary btn--block" type="submit" tabIndex="8" value="Submit" />
            <input type="hidden" id="idstamp" name="idstamp" value="aigGlg9ejD4SSbTgvGvMBi2KbLO4ypdDORidVG3Kleo=" />
           </div>
        </form>
      </div>
    </section>
  </EmptyLayout>
);

export default VIPiPhonePage
