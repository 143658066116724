import React from "react"
import PropTypes from "prop-types"

import BodyClass from "./body-class"
import "./layout.scss"

const EmptyLayout = ({
  bodyClassName,
  children,
}) => {
  return (
    <BodyClass
      bodyClassName={bodyClassName}
    >

      <main>
        {children}
      </main>

    </BodyClass>
  )
}

EmptyLayout.propTypes = {
  bodyClassName: PropTypes.string,
  children: PropTypes.node.isRequired,
}

export default EmptyLayout
